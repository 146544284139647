export const initialState = {
  user: {
      name: "",
      email: "",
      password: "",
      own_id: "",
      sponsor_id: "",
      position: "",
      parent_id: "",
      income: 0,
      TotalLeftCount: 0,
      TotalRightCount: 0,
      status: "in-active",
      wallet: 0,
      role: "",
  },
  "token": ""
}